///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1.25em;

		li {
			padding-left: 0.25em;
		}
	}

	ul {
		list-style: disc;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1em;

		li {
			padding-left: 0.5em;
			padding-bottom: 10px;
		}

		&.alt {
			list-style: none;
			padding-left: 0;

			li {
				border-top: solid 1px _palette(border);
				padding: 0.5em 0;

				&:first-child {
					border-top: 0;
					padding-top: 0;
				}
			}
		}

		&.icons {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 1em 0 0;

				&:last-child {
					padding-right: 0;
				}

				> * {
					@include icon;
					border: 0;

					.label {
						display: none;
					}
				}
			}
		}

		&.actions {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 (_size(element-margin) * 0.75) 0 0;
				vertical-align: middle;

				&:last-child {
					padding-right: 0;
				}
			}

			&.pagination {
				.next {
					@include icon('', after);

					&:after {
						content: '\f054';
					}
				}

				.previous {
					@include icon('', before);

					&:before {
						content: '\f053';
					}
				}

				@include breakpoint(large) {
					text-align: center;

					.next, .previous {
						min-width: 20em;
					}
				}

				@include breakpoint(small) {
					.next, .previous {
						min-width: 18em;
					}
				}
			}

			&.small {
				li {
					padding: 0 (_size(element-margin) * 0.5) 0 0;
				}
			}

			&.vertical {
				li {
					display: block;
					padding: (_size(element-margin) * 0.75) 0 0 0;

					&:first-child {
						padding-top: 0;
					}

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					li {
						padding: (_size(element-margin) * 0.5) 0 0 0;

						&:first-child {
							padding-top: 0;
						}
					}
				}
			}

			&.fit {
				display: table;
				margin-left: (_size(element-margin) * -0.5);
				padding: 0;
				table-layout: fixed;
				width: calc(100% + #{(_size(element-margin) * 0.5)});

				li {
					display: table-cell;
					padding: 0 0 0 (_size(element-margin) * 0.5);

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					margin-left: (_size(element-margin) * -0.25);
					width: calc(100% + #{(_size(element-margin) * 0.25)});

					li {
						padding: 0 0 0 (_size(element-margin) * 0.25);
					}
				}
			}

			@include breakpoint(xsmall) {
				margin: 0 0 _size(element-margin) 0;

				li {
					padding: (_size(element-margin) * 0.5) 0 0 0;
					display: block;
					text-align: center;
					width: 100%;

					&:first-child {
						padding-top: 0;
					}

					> * {
						width: 100%;
						margin: 0 !important;

						&.icon {
							&:before {
							}
						}
					}
				}

				&.small {
					li {
						padding: (_size(element-margin) * 0.25) 0 0 0;

						&:first-child {
							padding-top: 0;
						}
					}
				}
			}
		}

		&.posts {
			list-style: none;
			padding: 0;

			li {
				border-top: dotted 1px _palette(border);
				margin: (_size(element-margin) * 0.75) 0 0 0;
				padding: (_size(element-margin) * 0.75) 0 0 0;

				&:first-child {
					border-top: 0;
					margin-top: 0;
					padding-top: 0;
				}
			}

			article {
				@include vendor('display', 'flex');
				@include vendor('align-items', 'flex-start');
				@include vendor('flex-direction', 'row-reverse');

				.image {
					display: block;
					margin-right: 1.5em;
					min-width: 4em;
					width: 4em;

					img {
						width: 100%;
					}
				}

				header {
					@include vendor('flex-grow', '1');
					-ms-flex: 1;

					h3 {
						font-size: 0.7em;
						margin-top: 0.125em;
					}

					.published {
						display: block;
						font-family: _font(family-heading);
						font-size: 0.6em;
						font-weight: _font(weight-heading);
						letter-spacing: _font(kerning-heading);
						margin: -0.625em 0 (_size(element-margin) * 0.85) 0;
						text-transform: uppercase;
					}

					> :last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	dl {
		margin: 0 0 _size(element-margin) 0;

		dt {
			display: block;
			font-weight: _font(weight-bold);
			margin: 0 0 (_size(element-margin) * 0.5) 0;
		}

		dd {
			margin-left: _size(element-margin);
		}
	}
