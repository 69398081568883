// Contact page (/contact)

#contact {

  .email-at {
    margin: 3em 0;

    p {
      margin: 0;
    }

    .inline-container {
      width: 100%;

      span:focus {
        display: inline-block;
        outline: none;
        border: 0;
        text-align: right;
      }
    }
  }

  .inline {
    min-width: 150px;
    display: inline-block;
    margin: 80px;
    padding: 0;
    font-size: 15px;
    outline: 0;
    border: 0;
    text-align: right;
  }

  ul {
    margin-left: 0;
    padding-left: 0;
  }

  .email ul li a:hover {
    color: #861330FF;
    opacity: 0.8;
  }
}



